import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControlName, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { DialogData } from '../form-modifica-preventivo/form-modifica-preventivo.component';
import { debounceTime } from 'rxjs/operators';
import { Preventivo } from '../lista-preventivi/lista-preventivi.component';
export interface CercaData {
  nome: string;
  cognome: string;
  marcaVendita: string;
  modelloVendita: string;
  marcaPermuta: string;
  modelloPermuta: string;
  contratto: number;
  nuovoUsato: number;
  operatore: string;
  dataPartenza: string;
  dataArrivo: string;

}
@Component({
  selector: 'app-maschera-ricerca',
  templateUrl: './maschera-ricerca.component.html',
  styleUrls: ['./maschera-ricerca.component.scss']
})
export class MascheraRicercaComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MascheraRicercaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CercaData) { }
  list;
  filter: any;
  filtrata = new MatTableDataSource<DialogData>();

  chiudi(): void {
    console.log(this.ricercaForm.value);
    this.dialogRef.close(this.data);
  }
  chiudiConFiltro(): void {
    console.log(this.ricercaForm.value);
    //this.dialogRef.close(this.filtrata.data);

    this.dialogRef.close(this.ricercaForm.value);
  }
  ngOnInit() {
    console.log(this.data);
    console.log("SU");
    this.list = this.data;
    this.filtrata.data = this.list;
    console.log(this.filtrata.data);
    this.ricercaForm.controls.nuovoUsato.patchValue(this.data.nuovoUsato);
    this.ricercaForm.controls.operatore.patchValue(this.data.operatore);
    this.ricercaForm.controls.contratto.patchValue(this.data.contratto);
  }



  ricercaForm = new FormGroup({
    dataPartenza: new FormControl(this.data.dataPartenza == null ? null : new Date(this.data.dataPartenza)),
    dataArrivo: new FormControl(this.data.dataArrivo == null ? null : new Date(this.data.dataArrivo)),
    cognome: new FormControl(this.data.cognome),
    contratto: new FormControl(this.data.contratto),
    operatore: new FormControl(this.data.operatore),
    nuovoUsato: new FormControl(this.data.nuovoUsato),
    nome: new FormControl(this.data.nome),
    marcaVendita: new FormControl(this.data.marcaVendita),
    modelloVendita: new FormControl(this.data.modelloVendita),
    marcaPermuta: new FormControl(this.data.marcaPermuta),
    modelloPermuta: new FormControl(this.data.modelloPermuta),
  });

  operatori = [
    { nome: "", valore: null },
    { nome: "Luca", valore: "Luca" },
    { nome: "Alessandro C.", valore: "Alessandro C." },
    { nome: "Alessandro G.", valore: "Alessandro G." },
    { nome: "Dario", valore: "Dario" },
    { nome: "Daniele", valore: "Daniele" },
    { nome: "Davide", valore: "Davide" },
    { nome: "Mattia", valore: "Mattia"}
  ]
  nuovousato = [
    { nome: "", valore: null },
    { nome: "Nuovo", valore: 1 },
    { nome: "Usato", valore: 0 }

  ]

  finalizzazione = [
    { nome: "", valore: null },
    { nome: "Non finalizzato", valore: 0 },
    { nome: "Finalizzato", valore: 1 }

  ];


  resData() {
    this.ricercaForm.controls.dataArrivo.patchValue(null);
    this.ricercaForm.controls.dataPartenza.patchValue(null);

  }




}
