import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { FormModificaPreventivoComponent } from '../form-modifica-preventivo/form-modifica-preventivo.component';
import { MatDialog, MatTableDataSource, Sort } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { MascheraRicercaComponent } from '../maschera-ricerca/maschera-ricerca.component';
import { FormControl, FormControlDirective, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AuthGuardService } from 'src/app/auth/authGuard';
import { AuthService } from 'src/app/auth/authService';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface Preventivo {
  nome: string;
  cognome: string;
  localita: string;
  email: number;
  telefono: number;
  marcaVendita: string;
  modelloVendita: string;
  annoMotoVendita: number;
  kmMotoVendita: number;
  prezzoScontato: number;
  prezzoListino: number;
  spese: string;
  prezzoSpese: number;
  accessorioUno: string;
  accessorioDue: string;
  accessorioTre: string;
  prezzoAccessorioUno: number;
  prezzoAccessorioDue: number;
  prezzoAccessorioTre: number;
  prezzoFinale: number;
  totaleListino: number;
  marcaPermuta: string;
  modelloPermuta: string;
  annoMotoPermuta: number;
  kmMotoPermuta: number;
  targa: string;
  note: string;
  valutazione: number;
  finalizzato: number;
  nuovoUsato: number;
  operatore: string;
  data: string;
  id: number;
}





@Component({
  selector: 'app-lista-preventivi',
  templateUrl: './lista-preventivi.component.html',
  styleUrls: ['./lista-preventivi.component.scss']
})




@Injectable()
export class ListaPreventiviComponent implements OnInit {
  ricercaValues:any;
  displayedColumns: string[] = ['nuovousato', 'nome', 'email', 'vendita', 'permuta', 'prezzo', 'operatore', 'data'];
  dataSource = new MatTableDataSource<Preventivo>();
  loader: boolean;
  filterArray = new MatTableDataSource();
  list: Preventivo[];
  filtro = new FormControl();
  filterReset:boolean;
  filterValues = {
    name: '',
    id: '',
    colour: '',
    pet: ''
  };
  url = 'https://diegobrunero.it/mbk/auth/lista/';
  ngOnInit() {
    this.ricercaValues={
      nome:null,
      cognome:null,
      marcaVendita:null,
      modelloVendita:null,
      marcaPermuta:null,
      modelloPermuta:null,
      operatore:null,
      contratto:null,
      dataPartenza:null,
      dataArrivo:null,
      nuovoUsato:null
    }
    this.filterReset=true;
    this.loader = true;
    console.log("____");
    this.getPreventivi().subscribe((data) => {
      console.log(data);
      console.log("caca");
      this.dataSource.data = data;
      this.loader = false;
      // this.filterArray = new MatTableDataSource(this.dataSource);
      this.list = data;
    });

    //   this.filtro.valueChanges
    // .subscribe(
    //   nome => {
    //     console.log(nome);
    //     this.filterValues.name = nome;
    //     this.list.filter = JSON.stringify(nome);
    //   }
    // )
  }


refreshData(){
  this.dataSource.data=this.list;
  this.ricercaValues={
    nome:null,
    cognome:null,
    marcaVendita:null,
    modelloVendita:null,
    marcaPermuta:null,
    modelloPermuta:null,
    operatore:null,
    contratto:null,
    dataPartenza:null,
    dataArrivo:null,
    nuovoUsato:null
  }
  this.filterReset=true;
}
  result: any[];

  mySubscription: any;
  constructor(public http: HttpClient, public dialog: MatDialog, private router: Router) {

  }


  // tableFilter(): (data: any, filter: string) => boolean {
  //   let filterFunction = function(data, filter): boolean {
  //     let searchTerms = JSON.parse(filter);
  //     return data.nome.toLowerCase().indexOf(searchTerms.name) !== -1;
  //   }
  //   return filterFunction;
  //   }


  openDialog(el): void {
    console.log(el.name)
    const dialogRef = this.dialog.open(FormModificaPreventivoComponent, {


      data: {
        nome: el.nome,
        cognome: el.cognome,
        localita: el.localita,
        email: el.email,
        telefono: el.telefono,
        marcaVendita: el.marcaVendita,
        modelloVendita: el.modelloVendita,
        annoMotoVendita: el.annoMotoVendita,
        kmMotoVendita: el.kmMotoVendita,
        prezzoListino: el.prezzoListino,
        prezzoScontato: el.prezzoScontato,
        spese: el.spese,
        prezzoSpese: el.prezzoSpese,
        accessorioUno: el.accessorioUno,
        accessorioDue: el.accessorioDue,
        accessorioTre: el.accessorioTre,
        prezzoAccessorioUno: el.prezzoAccessorioUno,
        prezzoAccessorioDue: el.prezzoAccessorioDue,
        prezzoAccessorioTre: el.prezzoAccessorioTre,
        totaleListino: el.totaleListino,
        marcaPermuta: el.marcaPermuta,
        modelloPermuta: el.modelloPermuta,
        annoMotoPermuta: el.annoMotoPermuta,
        kmMotoPermuta: el.kmMotoPermuta,
        valutazione: el.valutazione,
        note: el.note,
        nuovoUsato: el.nuovoUsato,
        operatore: el.operatore,
        prezzoFinale: el.prezzoFinale,
        targa: el.targa,
        data: el.data,
        finalizzato: el.finalizzato,
        id: el.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.dataSource.data = [];
        this.loader = true;
        console.log("____");
        this.getPreventivi().subscribe((data) => {
          console.log(data);
          console.log("caca");
          // if(this.filtrata.data != []&& !this.filterReset){
          //   this.dataSource.data = this.filtrata.data;
          //   this.loader=false;
          // }else{
            this.list = data;
            if(!this.filterReset){
              this.filtroFn(this.ricercaValues);
            this.loader=false;
            }else{
              this.dataSource.data = data;
              this.loader = false;
            }
            // this.filtroFn(this.ricercaValues);
            // this.loader=false;
          // }
           
          // if(this.dataSource.data!=[]){
            
          //   this.loader=false;
          // }

        });

      }
    });


  }


  aggiornaLista(){
    this.dataSource.data = [];
    this.loader = true;
    console.log("____");
    this.getPreventivi().subscribe((data) => {
      console.log(data);
      console.log("caca");
      this.dataSource.data = data;
      if(this.dataSource.data!=[]){
        
        this.loader=false;
      }

    });

  }

  // applyFilter(event: Event) {
  //   console.log(this.list);
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   console.log(filterValue.trim().toLowerCase());
  //   this.dataSource.data = this.list.filter(riga => {
  //     riga.nome.trim().toLowerCase().includes(filterValue.trim().toLowerCase());
  //   });

  // }

  applyFilter(filtro) {
    console.log(filtro);
    console.log("AAAA");
    console.log(this.list);
    console.log("AAAA");
    this.dataSource.data = this.list
      .filter(obj => {
        return obj.cognome.trim().toLowerCase().includes(filtro.trim().toLowerCase())
      });
    console.log(this.dataSource.data);
  }
  //  headerDict = {
  //   'Authorization': 'Bearer '+sessionStorage.getItem('token');
  // }
  
  //  requestOptions = {                                                                                                                                                                                 
  //   headers: new Headers(this.headerDict), 
  // };
  getPreventivi() {
    
   
    return this.http.get<any>(this.url);

  }

  openFiltro() {
    alert("filtro");
  }
 

  filtrata= new MatTableDataSource<Preventivo>();
  filtroFn(ricercaForm) {
    // console.log(filtro);
    // console.log("AAAA");
    // console.log(this.list);
    // console.log("AAAA");
    console.log(ricercaForm);
    this.filtrata.data = this.list;
    let nome = ricercaForm.nome;
    let cognome = ricercaForm.cognome;
    let marcaVendita = ricercaForm.marcaVendita;
    let modelloVendita = ricercaForm.modelloVendita;
    let marcaPermuta = ricercaForm.marcaPermuta;
    let modelloPermuta = ricercaForm.modelloPermuta;
    let contratto = ricercaForm.contratto;
    let operatore = ricercaForm.operatore;
    let nuovoUsato = ricercaForm.nuovoUsato;
    let dataPartenza = ricercaForm.dataPartenza;
    let dataArrivo = ricercaForm.dataArrivo;

    this.ricercaValues = ricercaForm;
    console.log("RF");
    console.log(this.ricercaValues);
    console.log("RF");
    if(nome != null){
      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        return obj.nome.trim().toLowerCase().includes(nome.trim().toLowerCase())
      });
    console.log(this.filtrata.data);
    }
    if(cognome != null){
      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        return obj.cognome.trim().toLowerCase().includes(cognome.trim().toLowerCase())
      });
    console.log(this.filtrata.data);
    }
    if(marcaVendita != null){
      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        return obj.marcaVendita == null ? '' : obj.marcaVendita.trim().toLowerCase().includes(marcaVendita.trim().toLowerCase())
      });
    console.log(this.filtrata.data);
    }
    if(modelloVendita != null){
      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        return obj.modelloVendita == null ? '' : obj.modelloVendita.trim().toLowerCase().includes(modelloVendita.trim().toLowerCase())
      });
    console.log(this.filtrata.data);
    }
    if(marcaPermuta != null){
      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        return obj.marcaPermuta == null ? '' : obj.marcaPermuta.trim().toLowerCase().includes(marcaPermuta.trim().toLowerCase())
      });
    console.log(this.filtrata.data);
    }
    if(modelloPermuta != null){
      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        return obj.modelloPermuta == null ? '' : obj.modelloPermuta.trim().toLowerCase().includes(modelloPermuta.toLowerCase())
      });
    console.log(this.filtrata.data);
    }
    if(contratto != null){
      
      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        return obj.finalizzato.toString().trim().toLowerCase().includes(contratto.toString().trim().toLowerCase())
      });
    console.log(this.filtrata.data);
    }
    if(operatore != null){
      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        return obj.operatore.trim().toLowerCase().includes(operatore.trim().toLowerCase())
      });
    console.log(this.filtrata.data);
    }
    if(nuovoUsato != null){
      
      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        return obj.nuovoUsato.toString().trim().toLowerCase().includes(nuovoUsato.toString().trim().toLowerCase())
      });
    console.log(this.filtrata.data);
    }
    if(dataPartenza != null && dataArrivo == null){
    //  console.log((dataPartenza.getTime()+1).toJSON());

      this.filtrata.data = this.filtrata.data
      .filter(obj => {
        
        if(new Date(obj.data).getTime() > dataPartenza.getTime()){
         return new Date(obj.data).getTime() > dataPartenza.getTime();
        }
        //return obj.data > dataPartenza;
      });
   console.log(this.filtrata.data);
    }
    if(dataPartenza != null && dataArrivo != null){

      if(dataPartenza==dataArrivo){

          this.filtrata.data = this.filtrata.data
          
          .filter(obj => {
            
            if(dataPartenza.getTime()+86400000 == new Date(obj.data).getTime() && new Date(obj.data).getTime() == dataArrivo.getTime()+86400000){
             return (dataPartenza.getTime()+86400000 == new Date(obj.data).getTime() && new Date(obj.data).getTime()== dataArrivo.getTime()+86400000).toString();
            }
            //return obj.data > dataPartenza;
          });
       console.log(this.filtrata.data);
        
      }
      console.log((dataPartenza.getTime()+86400000));
      this.filtrata.data = this.filtrata.data
      
      .filter(obj => {
        
        if(dataPartenza.getTime()<= new Date(obj.data).getTime() && new Date(obj.data).getTime() <= dataArrivo.getTime()+86400000){
         return (dataPartenza.getTime() <= new Date(obj.data).getTime() && new Date(obj.data).getTime()<= dataArrivo.getTime()+86400000).toString();
        }
        //return obj.data > dataPartenza;
      });
   console.log(this.filtrata.data);
    }
    
    if(dataPartenza == null && dataArrivo != null){
      console.log(dataArrivo.getTime()+86400000);
      this.filtrata.data = this.filtrata.data
      
      .filter(obj => {
        
        if(new Date(obj.data).getTime() <= dataArrivo.getTime()+86400000){
         return new Date(obj.data).getTime() <= dataArrivo.getTime()+86400000;
        }
        //return obj.data > dataPartenza;
      });
      console.log(this.filtrata.data);
    }
    
    this.dataSource.data = this.filtrata.data;
    this.filterReset=false;
   
  }




  openCerca(): void {
    const dialogRefc = this.dialog.open(MascheraRicercaComponent, {
      data: this.ricercaValues
    });

    dialogRefc.afterClosed().subscribe(result => {
     
      this.filtroFn(result);
      if(result.nome==null
        && result.cognome==null
        && result.dataPartenza==null
        && result.dataArrivo==null
        && result.marcaVendita==null
        && result.modelloVendita==null
        && result.modelloPermuta==null
        && result.marcaPermuta==null
        && result.operatore==null
        && result.contratto==null
        && result.nuovoUsato==null){
        this.filterReset=true;
      }
     
      
    }); 
  }




}

